import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MdLiveHelp, MdReorder, MdKeyboardArrowDown, MdList } from 'react-icons/md';
import { Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { selectMeSettings } from '../../../me/meSlice';
import logoImage from '../../../../assets/img/logo/st_logo_line_white_color.png';
import SourceLink from '../../../../components/SourceLink';
import bn from '../../../utils/bemnames';
import { PRIVATE_ROUTES } from '../../../utils/pages';
import useProject from '../../../hooks/useProject';

const bem = bn.create('sidebar');

const Sidebar = function () {
  const settings = useSelector(selectMeSettings);
  const { home, pages, projects, currentProject } = settings;
  const project = useProject(currentProject);
  const pagesComponent = useMemo(
    () =>
      pages.map(rootItem => {
        const pageComponent = PRIVATE_ROUTES.find(
          private_page => private_page.name === rootItem.type,
        );
        if (!pageComponent) {
          return null;
        }

        return {
          id: uuidv4(),
          name: rootItem.type,
          title: pageComponent.title,
          path: rootItem.url,
          component: pageComponent.component,
          Icon: pageComponent.Icon,
          sidebar: rootItem.sidebar,
          group: rootItem.group,
        };
      }),
    [pages],
  );

  const onboardingMenu = pagesComponent.filter(page => page.sidebar && page.group === 'onboarding');
  const rootMenu = pagesComponent.filter(page => page.sidebar && !page.group);

  const [isOpenOnboarding, setIsOpenOnboarding] = useState(onboardingMenu.length > 0);

  const handleToggleOnboarding = () => setIsOpenOnboarding(!isOpenOnboarding);

  const renderOnboarding = () => (
    <>
      <NavItem className={bem.e('nav-item')} onClick={handleToggleOnboarding}>
        <BSNavLink className={bem.e('nav-item-collapse')}>
          <div className="d-flex">
            <MdReorder className={bem.e('nav-item-icon')} />
            <span className=" align-self-start">
              <strong>
                {project && project.project_type
                  ? project.project_type.toUpperCase()
                  : 'ONBOARDING'}
              </strong>
            </span>
          </div>
          <MdKeyboardArrowDown
            className={bem.e('nav-item-icon')}
            style={{
              padding: 0,
              transform: isOpenOnboarding ? 'rotate(0deg)' : 'rotate(-90deg)',
              transitionDuration: '0.3s',
              transitionProperty: 'transform',
            }}
          />
        </BSNavLink>
      </NavItem>
      <Collapse isOpen={isOpenOnboarding} className={bem.e('nav-collapse-list')}>
        {onboardingMenu.map(menu => {
          const { Icon } = menu;
          return menu.title === 'account-health-demo' ? null : (
            <NavItem key={`nav-item-${menu.id}`} className={bem.e('nav-item')}>
              <BSNavLink
                id={`nav-item-${menu.id}`}
                className="text-capitalize"
                tag={NavLink}
                to={menu.path}
                activeClassName="active"
                active={menu.id === home}
                exact
              >
                {Icon && <Icon className={bem.e('nav-item-icon')} />}
                <span className="">{menu.title}</span>
              </BSNavLink>
            </NavItem>
          );
        })}
      </Collapse>
    </>
  );

  return (
    <aside className={bem.b()}>
      <div
        className={bem.e('background')}
        style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
      />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img src={logoImage} width="190" height="auto" className="pt-3" alt="" />
          </SourceLink>
        </Navbar>
        <Nav vertical>
          {onboardingMenu.length > 0 && projects.length > 0 && renderOnboarding()}
          {rootMenu.map(menu => {
            const { Icon } = menu;
            return (
              <NavItem key={`nav-item-${menu.id}`} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`nav-link-${menu.id}`}
                  className="text-capitalize"
                  tag={NavLink}
                  to={menu.path}
                  activeClassName="active"
                  active={menu.id === home}
                  exact
                >
                  {Icon && <Icon className={bem.e('nav-item-icon')} />}
                  <span className="">{menu.title}</span>
                </BSNavLink>
              </NavItem>
            );
          })}
          {currentProject && (
            <NavItem key="dashboards" className={`${bem.e('nav-item')}`}>
              <BSNavLink
                id="nav-item-dashboards"
                className="text-capitalize"
                tag={NavLink}
                to="/journey"
                exact
              >
                <MdList className={bem.e('nav-item-icon')} />
                <span className=" align-self-start">Dashboards</span>
              </BSNavLink>
            </NavItem>
          )}
          <NavItem key="help" className={`${bem.e('nav-item')} nav-link`}>
            <a
              style={{ color: 'inherit', textDecoration: 'none' }}
              href="https://support.servicetrade.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdLiveHelp className={bem.e('nav-item-icon')} />
              <span className=" align-self-start">Help</span>
            </a>
          </NavItem>
        </Nav>
      </div>
    </aside>
  );
};

export default Sidebar;
