import React, { useState } from 'react';
import { Button } from '@mui/material';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Col, Input, Row, Spinner } from 'reactstrap';
import { AWS_USER_CREATE } from 'utils/utils';
import { postData } from '../utils/requests';
import { displayError } from '../features/utils/messages';
import bn from 'utils/bemnames';
const bem = bn.create('modal-customer-details');

const INITIAL_MODEL = {
    "UserName": "",
    "UserId": "",
    "CreateDate": "",
    "st_account_id": "",
}

const AccountHealthSettings = function (props) {
  const { projectId, companyName, token, project } = props;
  console.log(props, 'The component props');
  const aws_account = project.aws_account && Object.keys(project.aws_account).length ? project.aws_account : INITIAL_MODEL;
  const [model, setModel] = useState(aws_account)
  const spinnerClass = bem.b('row-spinner');
  const [loading, setLoading] = useState(false);

  const handleAccountIdChange = e => {
    setModel({...model, st_account_id: e.target.value});
  };

  const handleCreateAccount = async () => {
    const payload = {
      projectId,
      companyName,
      ...model
    };

    setLoading(true);
      await postData(`${AWS_USER_CREATE}/${projectId}`, payload, token)
      .then(({data}) => {
          setLoading(false);
          if (data) {
            setModel(data);
          }
          return data;
      }).catch((error) => {
        displayError(error.message);
      });
  };

  return loading ? (
    <Row className={spinnerClass}>
      <Spinner color="primary" />
    </Row>
  ) : (
    <div>
      <FormGroup style={{ marginTop: 25 }}>
        <Row>
          <Label sm={12} md={11} />
          <Col sm={12} md={6}>
            <Label> ServiceTrade Account ID</Label>
            <Input
                type="text"
                id="serviceTradeAccountID"
                name="serviceTradeAccountID"
                value={model.st_account_id}
                onChange={handleAccountIdChange}
            />
            <Label> User ID</Label>
            <Input
                value={model.UserId}
                type="text"
                id="text"
                disabled={true}
                style={{ minHeight: 25 }}
            />
          </Col>

          <Col sm={12} md={6}>
            <Label> User Name </Label>
            <Input
              type="text"
              id="text"
              value={model.UserName}
              disabled={true}
              style={{ minHeight: 25 }}
            />

            <Label> Created Date</Label>
            <Input
              type="text"
              id="text"
              disabled={true}
              value={model.CreateDate}
              style={{ minHeight: 25 }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup style={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
        <Row>
          <Col>
            <Button
              onClick={handleCreateAccount}
              style={{
                backgroundColor:'#004680',
              }}
              variant="contained"
            >
              {model.RequestId && ('Update')}
              {!model.RequestId && ('Create Account')}
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default AccountHealthSettings;
