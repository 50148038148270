import api from '../store/api';

const certificationAPI = api.injectEndpoints({
  endpoints: build => ({
    enrollmentsByCompany: build.query({
      query: company => `/certificates?company=${encodeURIComponent(company)}`,
    }),
    queuedEnrollmentsByEmail: build.query({
      query: email => `/queued/certificates/user?email=${encodeURIComponent(email)}`,
      providesTags: ['UserEnrollment'],
      keepUnusedDataFor: 2,
    }),
    queuedCreateLmsUser: build.mutation({
      query: ({ firstName, lastName, email, password, companyName }) => ({
        url: `/queued/certificates/user`,
        method: 'POST',
        body: { firstName, lastName, email, password, companyName },
      }),
    }),
    queuedEnroll: build.mutation({
      query: ({ userId, course }) => ({
        url: `/queued/certificates/user/enroll`,
        method: 'POST',
        body: { id: userId, course },
      }),
    }),
    queuedUnenroll: build.mutation({
      query: ({ email, course }) => ({
        url: `/queued/certificates/user/unenroll`,
        method: 'POST',
        body: { email, course },
      }),
    }),
    queuedBulkEditing: build.mutation({
      query: ({ companyName, file, lines, headers }) => ({
        url: `/queued/certificates/bulk`,
        method: 'POST',
        body: { companyName, file, lines, headers },
      }),
    }),
  }),
});

export const {
  useEnrollmentsByCompanyQuery,
  useQueuedEnrollmentsByEmailQuery,
  useQueuedEnrollMutation,
  useQueuedUnenrollMutation,
  useQueuedCreateLmsUserMutation,
  useQueuedBulkEditingMutation,
} = certificationAPI;
export default certificationAPI;
