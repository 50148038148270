import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

const NotificationAccordion = function (props) {
  const { subscribers = [], setSubscribers, title, bodyObject } = props;
  const deleteEmail = index => {
    setSubscribers({
      ...bodyObject,
      body: {
        ...bodyObject.body,
        subscribers: subscribers.filter(item => item !== subscribers[index]),
      },
    });
  };
  return (
    <div>
      <Accordion style={{ maxWidth: '80%' }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<MdExpandMore />}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>

        {subscribers.map((item, index) => {
          return (
            <AccordionDetails key={uuidv4()}>
              <Typography>
                {item}{' '}
                <Button onClick={() => deleteEmail(index)} color="white">
                  x
                </Button>
              </Typography>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </div>
  );
};

NotificationAccordion.propTypes = {
  title: PropTypes.string,
  setSubscribers: PropTypes.func,
  subscribers: PropTypes.array,
};

NotificationAccordion.defaultProps = {
  title: '',
  subscribers: [],
  setSubscribers: () => {},
};

export default NotificationAccordion;
