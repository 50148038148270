import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './features/store/store';
import GAListener from './components/GAListener';
import PageSpinner from './features/shared/components/PageSpinner';
import Routing from './features/shared/components/Routing';
import CurrentToastContainer from './features/shared/components/CurrentToastContainer';

import './styles/reduction.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<PageSpinner />}>
        <BrowserRouter basename="/">
          <GAListener>
            <Routing />
          </GAListener>
        </BrowserRouter>
      </Suspense>
      <CurrentToastContainer />
    </Provider>
  );
}

export default App;
