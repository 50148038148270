import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectCurrentProject } from '../project/projectSlice';
import { useGetProjectQuery } from '../project/projectApi';
import { useWebsocketContext } from '../websocket/context';

const useProject = function (projectId) {
  const [skip, setSkip] = useState(true);
  const { refetch } = useGetProjectQuery(projectId, { skip });
  const currentProject = useSelector(selectCurrentProject);

  const {
    subscription: { project: subscribedProject },
    subscribe,
    changeStatus,
  } = useWebsocketContext();

  useEffect(() => {
    if (projectId) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [projectId]);

  // subscribe to current account
  useEffect(() => {
    if (
      projectId &&
      projectId !== subscribedProject.id &&
      subscribedProject.status !== 'subscribing'
    ) {
      subscribe({ type: 'onboarding_project', item: projectId });
    }
  }, [projectId, subscribedProject]);

  // refetch if project modified
  useEffect(() => {
    if (projectId && subscribedProject.status === 'MODIFY') {
      refetch();
      changeStatus('processed', 'onboarding_project');
    }
  }, [projectId, subscribedProject]);

  // get updated project details
  useEffect(() => {
    if (projectId && currentProject && currentProject.projectId !== projectId) {
      refetch();
    }
  }, [projectId, currentProject]);

  return currentProject;
};

export default useProject;
