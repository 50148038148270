import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { ListGroupItem } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useSignOutMutation } from '../../me/meApi';

const Auth0LogOut = function () {
  const { logout } = useAuth0();
  const [signOut] = useSignOutMutation();
  const onClickLogOut = () => {
    signOut();
    logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
  };

  return (
    <ListGroupItem tag="button" action className="border-light" onClick={onClickLogOut}>
      <MdExitToApp /> Signout
    </ListGroupItem>
  );
};

export default Auth0LogOut;
