import React, { useState } from 'react';
import { Button } from '@mui/material';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Col, Input, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { SEND_NOTIFICATION_EMAIL } from '../utils/send_notification_conts';
import { postData } from '../utils/requests';
import { displayError, displaySuccess } from '../features/utils/messages';

const notificationFrequencyChoiceOptions = ['', 'Daily', 'Weekly', 'BiWeekly', 'Monthly'];
const taskTypeOptions = ['', 'summaryDigest', 'overdueTask'];

const SendNotification = function (props) {
  const { projectId, overdueTaskEmails, summaryDigestEmails, companyName, token } = props;
  const [frequencyValue, setFrequencyValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [clientMessage, setClientMessage] = useState('');

  const handleFrequencyChange = e => {
    setFrequencyValue(e.target.value);
  };

  const handleClientMessageChange = e => {
    setClientMessage(e.target.value);
  };

  const handleTaskTypeChange = e => {
    setTypeValue(e.target.value);
  };

  const handleSendNotification = async () => {
    const payload = {
      projectId,
      frequency: frequencyValue,
      notificationType: typeValue,
      message: clientMessage.split(`\n`),
      emails: typeValue === 'summaryDigest' ? summaryDigestEmails : overdueTaskEmails,
      companyName,
    };

    try {
      await postData(`${SEND_NOTIFICATION_EMAIL}/${projectId}`, payload, token);
      displaySuccess('Email sent');
    } catch (error) {
      displayError(error.message);
    }
    setFrequencyValue('');
    setTypeValue('');
    setClientMessage('');
  };

  return (
    <div>
      <FormGroup style={{ marginTop: 25 }}>
        <Row>
          <Label sm={12} md={11} />
          <Col sm={12} md={6}>
            <Label> Message to client</Label>
            <Input
              value={clientMessage}
              onChange={handleClientMessageChange}
              type="textarea"
              id="text"
              style={{ minHeight: 110 }}
            />
          </Col>

          <Col sm={12} md={6}>
            <Label> Task Type </Label>
            <Input
              onChange={handleTaskTypeChange}
              placeholder="Type"
              type="select"
              id="text"
              value={typeValue}
              style={{ minHeight: 25 }}
            >
              {taskTypeOptions.map(item => (
                <option key={uuidv4()}>{item}</option>
              ))}
            </Input>

            <Label> Frequency</Label>
            <Input
              onChange={handleFrequencyChange}
              placeholder="Type"
              type="select"
              id="text"
              value={frequencyValue}
              style={{ minHeight: 25 }}
            >
              {notificationFrequencyChoiceOptions.map(item => (
                <option key={uuidv4()}>{item}</option>
              ))}
            </Input>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup style={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
        <Row>
          <Col>
            <Button
              onClick={handleSendNotification}
              style={{
                backgroundColor:
                  typeValue.length < 1 || frequencyValue.length < 1 ? 'grey' : '#004680',
                color: 'white',
              }}
              variant="contained"
              disabled={typeValue.length < 1 || frequencyValue.length < 1}
            >
              Send Email
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default SendNotification;
