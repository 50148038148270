import { v4 as uuidv4 } from 'uuid';
import { MdDashboard, MdVerifiedUser, MdPersonAdd, MdAssessment } from 'react-icons/md';
import lazyWithRefresh from './lazyWithRefresh';

const LazyLoginPage = lazyWithRefresh(() => import('../shared/pages/auth/Login'));
const LazySignupPage = lazyWithRefresh(() => import('../shared/pages/auth/SignUp'));
const LazyResetPasswordPage = lazyWithRefresh(() => import('../shared/pages/ResetPassword'));
const LazyForgotPasswordPage = lazyWithRefresh(() => import('../shared/pages/auth/Forgot'));

const LazyEmptyPage = lazyWithRefresh(() => import('../shared/pages/Empty'));
const LazyOnboardingDashboardPage = lazyWithRefresh(() =>
  import('../shared/pages/onboarding/Dashboard'),
);
const LazyOnboardingDataPage = lazyWithRefresh(() => import('../shared/pages/onboarding/Data'));
const LazyOnboardingCertificationPage = lazyWithRefresh(() =>
  import('../shared/pages/onboarding/Certification'),
);
const LazyOnboardingAdminPage = lazyWithRefresh(() => import('../shared/pages/onboarding/Admin'));
const LazyOnboardingPage = lazyWithRefresh(() => import('../shared/pages/onboarding/Journey'));
const LazyCertificationPage = lazyWithRefresh(() => import('../shared/pages/Certification'));
const AccountHealthDemoPage = lazyWithRefresh(() =>
  import('../../pages/functional/AccountHealthDemo'),
);

const LazyNotFoundPage = lazyWithRefresh(() => import('../../pages/NotFoundPage'));
const LazyNoAccessPage = lazyWithRefresh(() => import('../shared/pages/NoAccess'));

export const DEFAULT_PUBLIC_ROUTES = [
  {
    id: uuidv4(),
    name: 'login',
    path: '/login',
    component: LazyLoginPage,
    exact: true,
  },
  {
    id: uuidv4(),
    name: 'signup',
    path: '/signup',
    component: LazySignupPage,
    exact: true,
  },
  {
    id: uuidv4(),
    name: 'reset',
    path: '/reset-password',
    component: LazyResetPasswordPage,
    exact: true,
  },
  {
    id: uuidv4(),
    name: 'forgot',
    path: '/forgot-password',
    component: LazyForgotPasswordPage,
    exact: true,
  },
];

export const DEFAULT_PRIVATE_ROUTES = [
  {
    id: uuidv4(),
    name: 'empty',
    path: '/empty',
    component: LazyEmptyPage,
    exact: true,
  },
  {
    id: uuidv4(),
    name: 'journey',
    path: '/journey',
    component: LazyOnboardingPage,
    exact: true,
  },
  {
    id: uuidv4(),
    name: '404',
    component: LazyNotFoundPage,
    path: '/404',
    exact: true,
  },
];

export const PRIVATE_ROUTES = [
  {
    id: uuidv4(),
    name: 'journey',
    title: 'journey',
    path: '/journey',
    component: LazyOnboardingPage,
    exact: true,
    Icon: null,
  },
  {
    id: uuidv4(),
    name: 'onboarding-dashboard',
    title: 'dashboard',
    path: '/dashboard',
    component: LazyOnboardingDashboardPage,
    exact: true,
    Icon: MdDashboard,
  },
  {
    id: uuidv4(),
    name: 'onboarding-data',
    title: 'data',
    path: '/data',
    component: LazyOnboardingDataPage,
    exact: true,
    Icon: MdAssessment,
  },
  {
    id: uuidv4(),
    name: 'onboarding-certification',
    title: 'certification',
    path: '/certification',
    component: LazyOnboardingCertificationPage,
    exact: true,
    Icon: MdVerifiedUser,
  },
  {
    id: uuidv4(),
    name: 'onboarding-admin',
    title: 'admin',
    path: '/admin-dashboard',
    component: LazyOnboardingAdminPage,
    exact: true,
    Icon: MdPersonAdd,
  },
  {
    id: uuidv4(),
    name: 'certification',
    title: 'certification',
    path: '/certification',
    component: LazyCertificationPage,
    exact: true,
    Icon: MdVerifiedUser,
  },
  {
    id: uuidv4(),
    name: 'account-health-demo',
    title: 'account-health-demo',
    path: '/account-health-demo',
    component: AccountHealthDemoPage,
    exact: true,
    Icon: MdVerifiedUser,
  },
  {
    id: uuidv4(),
    name: 'onboarding-account-health-demo',
    title: 'account-health-demo',
    path: '/account-health-demo',
    component: AccountHealthDemoPage,
    exact: true,
    Icon: MdVerifiedUser,
  },
  {
    id: uuidv4(),
    name: '404',
    title: '404',
    component: LazyNotFoundPage,
    path: '/404',
    exact: true,
    Icon: null,
  },
  {
    id: uuidv4(),
    name: 'no-access',
    title: 'no-access',
    component: LazyNoAccessPage,
    path: '/no-access',
    exact: true,
    Icon: null,
  },
];
