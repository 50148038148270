import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const MaterialUISwitch = styled(Switch)(props => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: '',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: props.oncolor,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: props.thumbcolor,
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: '',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: props.offcolor,
    borderRadius: 20 / 2,
  },
}));

const OnOffSwitch = function (props) {
  const {
    label,
    status,
    setValue,
    offColor = 'grey',
    onColor = 'green',
    thumbColor = 'white',
  } = props;

  const onChange = () => {
    if (status === true) setValue(false);
    else setValue(true);
  };

  return (
    <FormControlLabel
      onChange={onChange}
      control={
        <MaterialUISwitch
          sx={{ m: 1.5 }}
          defaultChecked={status}
          offcolor={offColor}
          oncolor={onColor}
          thumbcolor={thumbColor}
        />
      }
      label={label}
    />
  );
};

export default OnOffSwitch;
