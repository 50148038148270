import api from '../store/api';

const filesApi = api.injectEndpoints({
  endpoints: build => ({
    getFiles: build.query({
      query: ({ id, tags }) => `storage/bucket/getitems?projectId=${id}&tags=${tags}`,
      providesTags: ['Files'],
    }),
    getFile: build.query({
      query: ({ id, fileName }) => `storage/bucket/getFile?projectId=${id}&fileName=${fileName}`,
      providesTags: ['Files'],
    }),
  }),
});

export const { useGetFilesQuery, useGetFileQuery } = filesApi;

export default filesApi;
