import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Content from '../Layout/Content';

const PublicRoute = function (props) {
  const { path, name, exact, component: Component } = props;

  return (
    <Route
      key={`route-${name}`}
      path={path}
      exact={exact}
      render={() => (
        <main className="cr-app bg-light">
          <Content fluid>
            <Component />
          </Content>
        </main>
      )}
    />
  );
};

PublicRoute.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.shape({}).isRequired,
};

export default PublicRoute;
