import React from 'react';
import { getPageRefreshed, setPageRefreshed } from './localStorage';

const lazyWithRefresh = componentImport =>
  React.lazy(async () => {
    const pageRefreshed = getPageRefreshed();

    try {
      const component = await componentImport();
      setPageRefreshed(false);

      return component;
    } catch (error) {
      if (!pageRefreshed) {
        setPageRefreshed(true);
        return window.location.reload();
      }

      return import('../shared/components/ErrorBoundary');
    }
  });

export default lazyWithRefresh;
