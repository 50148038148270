import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { PRIVATE_ROUTES } from '../../../utils/pages';
import PrivateRoute from './PrivateRoute';
import useMe from '../../../hooks/useMe';
import { resetProject } from '../../../project/projectSlice';

const PrivateRouting = function (props) {
  const { token } = props;
  const { pathname } = useLocation();
  const { home, pages, settings } = useMe({ token });
  const dispatch = useDispatch();
  const currentRoutes = useMemo(() => {
    const memoPages = pages.map(page => {
      const pageComponent = PRIVATE_ROUTES.find(private_page => private_page.name === page.type);
      if (!pageComponent) {
        return null;
      }

      return {
        id: uuidv4(),
        name: page.type,
        path: page.url,
        exact: pageComponent.exact,
        component: pageComponent.component,
      };
    });

    memoPages.push(PRIVATE_ROUTES.find(route => route.name === '404'));

    return memoPages;
  }, [pages]);

  useEffect(() => {
    if (!settings.currentProject) {
      dispatch(resetProject());
    }
  }, [settings]);

  const homeRoute = currentRoutes.find(item => item.name === home);

  if (!pages || pages.length === 0) {
    return null;
  }

  const renderHome = () => (
    <Route path="/" exact render={() => <Redirect to={homeRoute ? homeRoute.path : '/'} />} />
  );

  const renderNotFound = () => {
    if (pathname === '/') {
      return null;
    }
    if (!currentRoutes.find(route => route.path === pathname)) {
      return <Redirect to="/404" />;
    }
    return null;
  };

  return (
    <>
      {renderHome()}
      {currentRoutes.map(route => {
        const { component, path, exact, id } = route;
        return <PrivateRoute key={id} id={id} path={path} exact={exact} component={component} />;
      })}
      {renderNotFound()}
    </>
  );
};

PrivateRouting.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PrivateRouting;
