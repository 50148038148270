import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import { resetProject } from '../project/projectSlice';
import invitationsApi from './invitationsApi';

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState: [],
  extraReducers: build => {
    build.addCase(resetToken, () => []);
    build.addCase(resetProject, () => []);
    build.addMatcher(
      invitationsApi.endpoints.getInvitations.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (data && data.invitations) {
          return [...data.invitations];
        }
        return state;
      },
    );
  },
});

export const invitationsActions = invitationsSlice.actions;
export const selectInvitations = state => state.invitations;

export default invitationsSlice.reducer;
