import api from '../store/api';

const invitationsApi = api.injectEndpoints({
  endpoints: build => ({
    getInvitations: build.query({
      query: id => `/invitations?projectId=${id}`,
      providesTags: ['Invitation'],
    }),
    createInvitation: build.mutation({
      query: ({ id, email, firstName, lastName, resend }) => ({
        url: `/invitation/${id}`,
        method: 'POST',
        body: {
          email,
          firstName,
          lastName,
          resend,
        },
      }),
      invalidatesTags: ['Invitation'],
    }),
    deleteInvitation: build.mutation({
      query: ({ id, username }) => ({
        url: `/invitation/${id}`,
        method: 'DELETE',
        body: { username },
      }),
      invalidatesTags: ['Invitation'],
    }),
  }),
});

export const { useGetInvitationsQuery, useCreateInvitationMutation, useDeleteInvitationMutation } =
  invitationsApi;

export default invitationsApi;
