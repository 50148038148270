import React from 'react';
import bn from '../../../utils/bemnames';

const Footer = function () {
  const bem = bn.create('footer');

  return (
    <footer>
      <div className={bem.e('container')}>
        <div>&copy; ServiceTrade, {new Date().getFullYear()}</div>
      </div>
    </footer>
  );
};

export default Footer;
