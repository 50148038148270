import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { selectToken } from '../../../auth/authSlice';
import { DEFAULT_PUBLIC_ROUTES } from '../../../utils/pages';
import PublicRoute from './PublicRoute';
import CommonProviders from '../../../../components/CommonProviders';
import PrivateRouting from './PrivateRouting';

const Routing = function () {
  const token = useSelector(selectToken);
  const renderPublicRoutes = useCallback(
    () =>
      DEFAULT_PUBLIC_ROUTES.map(route => {
        const { component, path, exact, name, id } = route;
        return <PublicRoute key={id} name={name} path={path} exact={exact} component={component} />;
      }),
    [],
  );

  const renderPrivateRoutes = useCallback(
    () => (
      <CommonProviders>
        <PrivateRouting token={token} />
      </CommonProviders>
    ),
    [],
  );

  return (
    <Switch>
      {renderPublicRoutes()}
      {token ? renderPrivateRoutes() : <Redirect exact to="/login" />}
    </Switch>
  );
};

export default Routing;
