import api from '../store/api';

const projectApi = api.injectEndpoints({
  endpoints: build => ({
    getProject: build.query({
      query: id => `/project/${id}`,
      providesTags: ['Project'],
    }),
    setLogo: build.mutation({
      query: ({ id, logo }) => ({
        url: `/project/value/${id}`,
        method: 'POST',
        body: { field: 'logo', value: logo },
      }),
      invalidatesTags: ['Project'],
    }),
    setUpdatedTask: build.mutation({
      query: ({ id, task }) => ({
        url: `/project/task/${id}`,
        method: 'POST',
        body: { task },
      }),
      invalidatesTags: ['Project'],
    }),
  }),
});

export const { useGetProjectQuery, useSetLogoMutation, useSetUpdatedTaskMutation } = projectApi;

export default projectApi;
