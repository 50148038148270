import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Layout from '../Layout';

const PrivateRoute = function (props) {
  const { path, id, exact, component: Component } = props;

  return (
    <Route
      key={`route-${id}`}
      path={path}
      exact={exact}
      render={() => (
        <main className="cr-app bg-light">
          <Layout>
            <Component />
          </Layout>
        </main>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.shape({}).isRequired,
};

export default PrivateRoute;
