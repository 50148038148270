import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import fetch, { Request, Response } from 'cross-fetch';
import BACKEND_URL from '../utils/urls';

const { NODE_ENV } = process.env;

const createBaseQuery = () => {
  if (NODE_ENV === 'test') {
    global.Request = Request;
    global.Response = Response;

    return fetchBaseQuery({
      baseUrl: BACKEND_URL,
      prepareHeaders: (headers, { getState }) => {
        const { token } = getState().auth;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      },
      fetchFn: fetch,
    });
  }

  return fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
};

const api = createApi({
  baseQuery: createBaseQuery(),
  tagTypes: [
    'User',
    'Project',
    'Users',
    'Invitation',
    'Enrollment',
    'UserEnrollment',
    'Files',
    'Queued',
  ],
  endpoints: () => ({}),
  overrideExisting: true,
});

export default api;
