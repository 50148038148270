import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import meAPI from './meApi';

export const initialUserSettings = {
  home: '',
  currentProject: null,
  projects: [],
  tutorials: [],
  pages: [],
  ui: [],
  roles: [],
};

const initialUserDetails = {
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  avatar: {},
  account: {},
  type: '',
};

const meSlice = createSlice({
  name: 'me',
  initialState: { user: initialUserDetails, settings: initialUserSettings },
  reducers: {},
  extraReducers: build => {
    build.addCase(resetToken, state => {
      state.user = initialUserDetails;
      state.settings = initialUserSettings;
    });
    build.addMatcher(meAPI.endpoints.me.matchFulfilled, (state, { payload }) => {
      const { data } = payload;
      if (data) {
        const { username, firstName, lastName, company, avatar, settings, roles, type } = data;
        state.user = {
          ...initialUserDetails,
          email: username,
          firstName,
          lastName,
          company,
          avatar,
          type,
        };
        state.settings = {
          ...initialUserSettings,
          ...settings,
          roles,
        };
      }
    });
    build.addMatcher(meAPI.endpoints.signOut.matchFulfilled, state => {
      state.user = initialUserDetails;
      state.settings = initialUserSettings;
    });
  },
});

export const meActions = meSlice.actions;

export default meSlice.reducer;

export const selectMeDetails = state => state.me.user;
export const selectMeSettings = state => state.me.settings;
