import moment from 'moment';

const formatUnixToDate = (num, format = 'yyyy-MM-DD') => {
  if (typeof num === 'number') {
    const multiplier = moment(num).valueOf() < moment('2000-01-01').valueOf() ? 1000 : 1;
    return moment(num * multiplier)
      .format(format)
      .toString();
  }
  return num || '';
};

const dates = {
  formatUnixToDate,
};

export default dates;
