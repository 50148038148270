import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGetQuery } from './queuedAPI';
import QUEUED_CALL from '../utils/queued';
import { displaySuccess, displayError } from '../utils/messages';

const QueuedContext = createContext();

const QueuedProvider = function ({ children }) {
  // const [skip, setSkip] = useState(true);
  const [queueds, setQueueds] = useState([]);
  const currentItem = queueds && queueds[queueds.length - 1]?.id;
  const { data } = useGetQuery(currentItem, { skip: !currentItem });

  const addQueued = useCallback(
    item => {
      setQueueds([...queueds, item]);
    },
    [queueds, setQueueds],
  );

  useEffect(() => {
    if (currentItem && data?.data?.guid === currentItem) {
      const { action, status, params, error, errorMessage } = data?.data || {
        action: null,
        status: null,
        params: null,
        error: null,
        errorMessage: null,
      };
      switch (action) {
        case QUEUED_CALL.CERTIFICATE.USER_BY_EMAIL: {
          if (error) {
            displayError(`User details for ${params.email} getting with error: ${errorMessage}`);
          } else {
            displaySuccess(
              `User details for ${params.email} is ready with status ${status.toLowerCase()}`,
            );
          }
          break;
        }
        case QUEUED_CALL.CERTIFICATE.CREATE_USER: {
          if (error) {
            displayError(`User creation for ${params.email} getting error: ${errorMessage}`);
          } else {
            displaySuccess(
              `User is created for ${params.email} with status ${status.toLowerCase()}`,
            );
          }
          break;
        }
        case QUEUED_CALL.CERTIFICATE.USER_ENROLL: {
          displaySuccess(
            `Enroll for the course: ${params.course} with status ${status.toLowerCase()}`,
          );
          break;
        }
        case QUEUED_CALL.CERTIFICATE.USER_UNENROLL: {
          displaySuccess(
            `Unenroll from the course: ${params.course} with status ${status.toLowerCase()}`,
          );
          break;
        }
        case QUEUED_CALL.CERTIFICATE.BULK_EDITING: {
          displaySuccess(`Bulk editing file: ${params.file} with status ${status.toLowerCase()}`);
          break;
        }
        default:
          break;
      }
      setQueueds(queueds.filter(item => item.id !== currentItem));
    }
  }, [data]);

  // useEffect(() => {
  //   console.log('should change skip');
  //   setSkip(!currentItem);
  // }, [currentItem]);

  const value = useMemo(() => ({ addQueued }), [addQueued]);
  return <QueuedContext.Provider value={value}>{children}</QueuedContext.Provider>;
};

QueuedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useQueuedContext = () => {
  const ctx = useContext(QueuedContext);
  if (ctx === undefined) {
    throw new Error('useConnectionState must be used within a DataProvider');
  }

  return ctx;
};

export { QueuedProvider, useQueuedContext };
