import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import ServiceTradeAccounts from './ServiceTradeAccounts';
import dates from '../../utils/dates';
import { PROJECT_STATE, getProjectState } from '../../utils/project';

const { formatUnixToDate } = dates;

const ProjectDetailsForm = function (props) {
  const { details, handleChange, isBlank, accounts } = props;
  const [projectState, setProjectState] = useState(
    getProjectState(details.project_archived, details.project_disabled),
  );
  const handleChangeState = event => {
    const { value } = event.target;
    if (value.toLowerCase() === PROJECT_STATE.ACTIVE) {
      handleChange({ target: { name: 'project_disabled', value: false } });
    }
    if (value.toLowerCase() === PROJECT_STATE.DISABLED) {
      handleChange({ target: { name: 'project_disabled', value: true } });
    }
  };
  const renderStateOptions = () => {
    return Object.values(PROJECT_STATE).map(item => (
      <option
        key={`option-${item}`}
        value={item.toUpperCase()}
        disabled={item === PROJECT_STATE.ARCHIVED && !details.project_archived}
      >
        {item.toUpperCase()}
      </option>
    ));
  };
  useEffect(
    () => setProjectState(getProjectState(details.project_archived, details.project_disabled)),
    [details],
  );

  return (
    <fieldset disabled={projectState === PROJECT_STATE.ARCHIVED}>
      <FormGroup row>
        <Label sm={5} lg={4} for="serviceTradeTier">
          ServiceTrade Tier
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="select"
            name="serviceTradeTier"
            id="serviceTradeTier"
            value={details.serviceTradeTier}
            disabled={isBlank}
            onChange={handleChange}
          >
            <option value="Select">Select</option>
            <option value="Premium">Premium</option>
            <option value="Enterprise">Enterprise</option>
            <option value="Demo">Demo</option>
            <option value="Self-Starter">Self-Starter</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="licenseCount">
          License Count
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="text"
            id="licenseCount"
            name="licenseCount"
            disabled={isBlank}
            value={details.licenseCount}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="salesforceId">
          Salesforce ID
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="text"
            id="salesforceId"
            name="salesforceId"
            value={details.salesforceId}
            disabled={isBlank}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="asanaId">
          Asana Project ID
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="text"
            id="asanaId"
            disabled={!isBlank}
            name="asanaId"
            value={details.asanaId}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="subsriptionStart">
          Subscription Start
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="date"
            name="subsriptionStart"
            id="subsriptionStart"
            value={formatUnixToDate(details.subsriptionStart)}
            disabled={isBlank}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="subsriptionEnd">
          Subscription End
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="date"
            id="subsriptionEnd"
            name="subsriptionEnd"
            disabled={isBlank}
            value={formatUnixToDate(details.subsriptionEnd)}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="goLiveDate">
          Go Live Date
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="date"
            id="goLiveDate"
            name="goLiveDate"
            value={formatUnixToDate(details.goLiveDate)}
            disabled={isBlank}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        {accounts.length > 0 && (
          <ServiceTradeAccounts title="ServiceTrade Accounts" list={accounts} />
        )}
      </FormGroup>
      <FormGroup row>
        <Label sm={5} lg={4} for="state">
          State
        </Label>
        <Col sm={7} lg={8}>
          <Input
            type="select"
            id="state"
            name="state"
            value={projectState.toUpperCase()}
            disabled={isBlank}
            onChange={handleChangeState}
          >
            {isBlank && <option value="Select">Select</option>}
            {renderStateOptions()}
          </Input>
        </Col>
      </FormGroup>
    </fieldset>
  );
};

ProjectDetailsForm.propTypes = {
  details: PropTypes.shape({
    serviceTradeTier: PropTypes.string,
    licenseCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    salesforceId: PropTypes.string,
    asanaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subsriptionStart: PropTypes.string,
    subsriptionEnd: PropTypes.string,
    goLiveDate: PropTypes.string,
    state: PropTypes.string,
    project_disabled: PropTypes.bool,
    project_archived: PropTypes.bool,
  }).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      accountName: PropTypes.string,
      accountType: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  isBlank: PropTypes.bool.isRequired,
};

export default ProjectDetailsForm;
