import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectSelection from './ProjectSelection';
import EditAccountButton from './EditAccountButton';
import ModalCustomerDetails from '../../../components/ModalCustomerDetails';
import permission from '../../utils/permission';

const { isProjectAdmin } = permission;

const CurrentProject = function (props) {
  const { roles, currentProject } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isBlank, setIsBlank] = useState(false);
  const showEditProject = (edit, blank = false) => {
    setIsEdit(edit);
    setIsBlank(blank);
  };

  return (
    <>
      {isProjectAdmin(currentProject, roles) && (
        <>
          <ProjectSelection openBlankForm={() => showEditProject(true, true)} />
          {currentProject && <EditAccountButton onEdit={() => showEditProject(true)} />}
        </>
      )}
      {isEdit && (
        <ModalCustomerDetails isBlank={isBlank} toggleDetails={() => showEditProject(false)} />
      )}
    </>
  );
};

CurrentProject.propTypes = {
  currentProject: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CurrentProject.defaultProps = {
  currentProject: null,
};

export default CurrentProject;
