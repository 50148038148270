import api from '../store/api';

const meAPI = api.injectEndpoints({
  endpoints: build => ({
    me: build.query({
      query: () => '/me',
    }),
    setSetting: build.mutation({
      query: setting => ({
        url: `/me`,
        method: 'POST',
        body: { setting },
      }),
    }),
    signOut: build.mutation({
      query: () => ({
        url: `/me/signout`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useMeQuery, useSetSettingMutation, useSignOutMutation } = meAPI;

export default meAPI;
