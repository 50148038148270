import api from './api';
import authReducer from '../auth/authSlice';
import meReducer from '../me/meSlice';
import certificationReducer from '../certification/certificationSlice';
import projectReducer from '../project/projectSlice';
import filesReducer from '../files/filesSlice';
import invitationsReducer from '../invitations/invitationsSlice';
import usersReducer from '../users/usersSlice';
import queuedReducer from '../queued/queuedSlice';

const reducer = {
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  me: meReducer,
  certification: certificationReducer,
  project: projectReducer,
  files: filesReducer,
  invitations: invitationsReducer,
  users: usersReducer,
  queued: queuedReducer,
};

export default reducer;
