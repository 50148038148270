import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';

const EditAccountButton = function (props) {
  const { onEdit } = props;

  const onEditClick = () => onEdit(true);

  const handleKeydown = event => {
    if (event.keyCode === 13) {
      onEditClick();
    }
  };

  return (
    <div
      onClick={onEditClick}
      onKeyDown={handleKeydown}
      role="button"
      style={{ cursor: 'pointer', margin: 'auto', paddingLeft: 10 }}
      tabIndex="0"
    >
      <EditIcon color="primary" />
    </div>
  );
};

EditAccountButton.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default EditAccountButton;
