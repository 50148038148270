import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import { resetProject } from '../project/projectSlice';
import filesApi from './filesApi';
import { TAGS } from '../../components/DataUpload/data-upload-utils';

export const initialFiles = () => {
  const tags = TAGS.split(',');
  const result = {};
  tags.forEach(tag => {
    result[tag] = [];
  });
  return result;
};

const filesSlice = createSlice({
  name: 'files',
  initialState: { ...initialFiles() },
  extraReducers: build => {
    build.addCase(resetToken, () => ({ ...initialFiles() }));
    build.addCase(resetProject, () => ({ ...initialFiles() }));
    build.addMatcher(filesApi.endpoints.getFiles.matchFulfilled, (state, { payload }) => {
      const { data } = payload;
      if (data && data.list) {
        const files = initialFiles();
        data.list.forEach(item => {
          files[item[0]] = [...item[1]];
        });
        return { ...files };
      }
      return state;
    });
  },
});

export const filesActions = filesSlice.actions;
export const selectFiles = state => state.files;

export default filesSlice.reducer;
