import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withSize } from 'react-sizeme';
import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import breakpoint from '../../../utils/breakpoint';
import { openSidebar, isSidebarOpen } from '../../../utils/sidebar';

const Layout = function (props) {
  const { size, children } = props;
  const { width } = size;

  const current = useMemo(() => breakpoint(width), [width]);

  const handleContentClick = useCallback(() => {
    if (isSidebarOpen() && (current === 'xs' || current === 'sm' || current === 'md')) {
      openSidebar('close');
    }
  }, [openSidebar]);

  useEffect(() => {
    switch (current) {
      case 'xs':
      case 'sm':
      case 'md': {
        openSidebar('close');
        break;
      }
      case 'lg':
      case 'xl':
      default: {
        openSidebar('open');
        break;
      }
    }
  }, [current]);

  return (
    <main className="cr-app bg-light">
      <Sidebar />
      <Content fluid onClick={handleContentClick}>
        <Header />
        {children}
        <Footer />
      </Content>
    </main>
  );
};

Layout.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default withSize()(Layout);
