import { createSlice } from '@reduxjs/toolkit';
import certificationAPI from './certificationAPI';
import { resetToken } from '../auth/authSlice';
import { defineUserDetails } from '../utils/certification';

const initialUserEnrollments = defineUserDetails();

const initialState = {
  companyEnrollments: [],
  userEnrollments: initialUserEnrollments,
  bulkEditing: [],
};

const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    resetUserEnrollments: state => {
      state.userEnrollments = initialUserEnrollments;
    },
    addEnrollmentsByEmail: (state, { payload: data }) => {
      if (data && data.response) {
        const { user } = data.response;
        if (!user) {
          state.userEnrollments = {
            ...initialUserEnrollments,
            email: data?.params.email,
          };
        } else {
          const { id, first_name, last_name, email, company, enrollments } = user;
          state.userEnrollments = {
            id,
            firstName: first_name,
            lastName: last_name,
            email,
            company,
            enrollments: initialUserEnrollments.enrollments.map(element => {
              const currentElement =
                enrollments && enrollments.find(el => el.courseId === element.id);
              if (currentElement) {
                return { ...element, value: { ...currentElement, loading: false } };
              }
              return element;
            }),
          };
        }
      } else if (data && data.params) {
        state.userEnrollments = {
          ...initialUserEnrollments,
          email: data?.params.email,
        };
      }
    },
    addNewUser: (state, { payload: data }) => {
      if (data && data.response) {
        const { id, email, first_name, last_name } = data.response;
        state.userEnrollments = {
          ...initialUserEnrollments,
          id,
          email,
          firstName: first_name,
          lastName: last_name,
        };
      }
    },
    enrollCourse: (state, { payload: data }) => {
      if (data) {
        const { response } = data;
        if (response) {
          const newUserEnrollments = state.userEnrollments.enrollments.map(item => {
            if (item.id === response[0].courseId) {
              return {
                ...item,
                value: { ...response[0], loading: false },
              };
            }
            return item;
          });
          state.userEnrollments = {
            ...state.userEnrollments,
            enrollments: newUserEnrollments,
          };
        }
      }
    },
    unenrollCourse: (state, { payload: data }) => {
      if (data) {
        const { params } = data;
        if (params) {
          const newUserEnrollments = state.userEnrollments.enrollments.map(item => {
            if (item.id === params.course) {
              return {
                ...item,
                value: null,
              };
            }
            return item;
          });
          state.userEnrollments = {
            ...state.userEnrollments,
            enrollments: newUserEnrollments,
          };
        }
      }
    },
    bulkEditing: (state, { payload: data }) => {
      if (data) {
        const { guid, params, response, createdAt } = data;
        if (state.bulkEditing.find(item => item.guid === guid)) {
          state.bulkEditing = state.bulkEditing.map(item => {
            if (item.guid === guid) {
              return { ...item, lines: response ? response.result.lines : null, createdAt };
            }
            return item;
          });
        } else {
          const newBulkEditing = [...state.bulkEditing];
          newBulkEditing.push({ guid, params, lines: null, createdAt: null });
          if (newBulkEditing.length > 5) {
            newBulkEditing.shift();
          }

          state.bulkEditing = [...newBulkEditing];
        }
      }
    },
    bulks: (state, { payload: data }) => {
      if (data && Array.isArray(data)) {
        state.bulkEditing = data.map(item => {
          const { guid, params, response, createdAt } = item;
          if (response?.result?.lines) {
            return { ...item, lines: response ? response.result.lines : null, createdAt };
          }
          return { guid, params, lines: null, createdAt: null };
        });
      }
    },
  },
  extraReducers: build => {
    build.addCase(resetToken, state => {
      state.companyEnrollments = [];
      state.userEnrollments = initialUserEnrollments;
    });
    build.addMatcher(
      certificationAPI.endpoints.enrollmentsByCompany.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (data) {
          state.companyEnrollments = [...data];
        }
      },
    );
  },
});

export const certificationActions = certificationSlice.actions;

export default certificationSlice.reducer;

export const selectUserEnrollments = state => state.certification.userEnrollments;

export const selectCompanyEnrollments = state => state.certification.companyEnrollments;

export const selectBulkEditingResult = state => state.certification.bulkEditing;
