import { configureStore } from '@reduxjs/toolkit';
import api from './api';
import reducer from './reducer';
import authMiddleware from '../auth/authMiddleware';
import errorMiddleware from './errorMiddleware';

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([api.middleware, authMiddleware, errorMiddleware]),
  devTools: process.env.REACT_APP_STAGE !== 'prod',
});

export default store;
