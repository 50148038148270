import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import projectApi from './projectApi';

const initialMath = {
  daysRemaining: 0,
  swColor: 'success',
  swProgressBar: null,
  taskColor: 'success',
  taskProgressBar: 0,
  tasksRemaining: 0,
  totalDays: 0,
  totalTasks: 25,
};

const initialAsana = {
  project: {
    name: '',
    owner: {
      gid: '',
      email: '',
      name: '',
      photo: {
        image_60x60: '',
      },
      about_me: '',
    },
    project_roles: [],
    status: 'On Track',
  },
  tasks: [],
  projectMileStone: [],
};

const initialProject = {
  projectId: 0,
  name: '',
  projectManager: 1,
  service_end_date: '',
  service_start_date: '',
  salesforceNotes: {},
  subscription: '',
  licenses: 0,
  serviceTradeAccounts: [],
  subscription_end_date: '',
  subscription_start_date: '',
  logo: '',
  salesForceOpportunityId: '',
  projectMath: initialMath,
  asanaData: initialAsana,
  aws_account: {},
  invoices: [],
};

const projectSlice = createSlice({
  name: 'project',
  initialState: { ...initialProject },
  reducers: {
    resetProject: () => ({ ...initialProject }),
  },
  extraReducers: build => {
    build.addCase(resetToken, () => ({ ...initialProject }));
    build.addMatcher(projectApi.endpoints.getProject.matchFulfilled, (state, { payload }) => {
      const { data } = payload;
      return data ? { ...initialProject, ...data } : state;
    });
  },
});

export const { resetProject } = projectSlice.actions;

export default projectSlice.reducer;

export const selectCurrentProject = state => state.project;
