import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PromptContext } from '../context/prompt';
import { showPromptInitialState } from '../state/prompt';
import { WebsocketProvider } from '../features/websocket/context';
import { QueuedProvider } from '../features/queued/context';

const CommonProviders = function (props) {
  const { children } = props;
  const [triggerAlerts, setTriggerAlerts] = useState(showPromptInitialState);
  const propmt = useMemo(() => ({ triggerAlerts, setTriggerAlerts }), []);

  return (
    <PromptContext.Provider value={propmt}>
      <QueuedProvider>
        <WebsocketProvider>{children}</WebsocketProvider>
      </QueuedProvider>
    </PromptContext.Provider>
  );
};

CommonProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommonProviders;
