import { toast } from 'react-toastify';

export const ERRORS = {
  SERVER: 'Server error. Try it later.',
  AUTH: 'Unauthorized request',
  NOT_FOUND: 'Not found',
  USER: {
    DELETE: 'There was an error deleting the user: ',
  },
  UPLOAD: {
    BUCKET_EXIST: 'There was an error retrieving bucket details: ',
    FILE: 'There was an error uploading file: ',
    GET_FILES: 'There was an error retrieving files: ',
    DELETE_FILE: 'There was an error deleting file: ',
  },
  TASK: {
    GET_BYTYPE: 'There was an error retrieving task by type: ',
    SUBMIT: 'There was an error submitting task: ',
  },
  ME: {
    GET: 'There was an error getting current user: ',
  },
  PROJECT: {
    GET: 'There was an error getting project: ',
    GET_USERS: 'There was an error getting project users: ',
    GET_INVITATIONS: 'There was an error getting project invitations: ',
  },
  CERTS: {
    GET: 'There was an error getting certificates: ',
  },
  INVITATION: {
    DELETE: 'There was an error deleting invitation: ',
    SENT: 'There was an error sending invitation: ',
  },
};

export const MESSAGES = {
  INVITATION: {
    DELETE: 'User invitation deleted successfully: ',
    SENT: 'User invitation sent successfully: ',
  },
  USER: {
    DELETE: 'User deleted successfully: ',
  },
  UPLOAD: {
    FILE: 'File uploaded successfully: ',
    DELETE_FILE: 'File deleted successfully: ',
  },
  TASK: {
    GET_BYTYPE: '',
  },
  CERTS: {
    BULK: {
      UPLOAD: 'Uploaded successfully: ',
      UPLOAD_WARNING: 'Uploaded with comments',
    },
  },
};

export const displayError = text => {
  toast.error(text || ERRORS.SERVER, {
    theme: 'colored',
  });
};

export const displaySuccess = (text, title) => {
  const currentText = title ? `${title}: ${text}` : text;
  toast.success(currentText, { theme: 'colored' });
};

export const displayInfo = text => toast.info(text, { theme: 'colored' });

export const displayWarning = text => toast.warn(text, { theme: 'colored' });
