import api from '../store/api';

const usersApi = api.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query({
      query: id => `/users?projectId=${id}`,
      providesTags: ['Users'],
    }),
    deleteUser: build.mutation({
      query: ({ id, username }) => ({
        url: `/user/${id}`,
        method: 'DELETE',
        body: { username },
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useGetUsersQuery, useDeleteUserMutation } = usersApi;

export default usersApi;
