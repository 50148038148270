export const isSidebarOpen = () => {
  return document.querySelector('.cr-sidebar').classList.contains('cr-sidebar--open');
};

export const openSidebar = openOrClose => {
  if (openOrClose === 'open') {
    document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    document.querySelector('header').classList.add('cr-header__with-sidebar');
    return true;
  }
  document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  document.querySelector('header').classList.remove('cr-header__with-sidebar');
  return true;
};

export const toggleSidebar = () => {
  if (isSidebarOpen()) {
    openSidebar('close');
  } else {
    openSidebar('open');
  }
};
