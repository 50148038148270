const responseError = (code) => ({
    error: true,
    statusCode: code
})

async function fetchData(url, params) {
    const response = await fetch(url, params);
    if(response.ok) {
        return response;       
    }

    return responseError(response.status);
}

async function fetchDataJSON(url, params) {
    const response = await fetch(url, params);
    if(response.ok) {
        return await response.json();       
    }

    return responseError(response.status);
}

export async function getData(url='', token='') {
    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token !== '') params.headers['Authorization'] = `Bearer ${token}`;

    return await fetchDataJSON(url, params);
}

export async function postData(url = '', data = {}, token='', contentType='application/json') {
    const params = {
        method: 'POST',
        headers: { 'Content-Type': contentType },
        body: JSON.stringify(data)
    }
    if(token !== '') {
        params.headers['Authorization'] = `Bearer ${token}`
    }
    
    return await fetchDataJSON(url, params);
}

export async function deleteData(url = '', data = {}, token='') {
    const params = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return await fetchDataJSON(url, params);
}

export async function putData(url = '', data = {}, header={}, token='', contentLenght=0) {
    const isJson = header['Content-Type'] === 'application/json'; 
    const params = {
        method: 'PUT',
        ContentLength: contentLenght,
        headers: header,
        redirect: 'follow',
        body: isJson ? JSON.stringify(data): data
    }

    if (token !== '') params.headers['Authorization'] = `Bearer ${token}`;

    return isJson ? await fetchDataJSON(url, params) : await fetchData(url, params);
}