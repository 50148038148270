import { createSlice } from '@reduxjs/toolkit';
import localStorage from '../utils/localStorage';

const initialState = {
  token: localStorage.getAuthToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload: token }) => {
      state.token = token;
    },
    resetToken: state => {
      state.token = null;
    },
  },
});

export const { setToken, resetToken } = authSlice.actions;

export default authSlice.reducer;

export const selectToken = state => state.auth.token;
