const isOnboardingAdmin = roles => roles.includes('onboarding-admin');

const isProjectAdmin = (currentProject, roles) => {
  if (isOnboardingAdmin(roles)) {
    return true;
  }

  if (roles.includes(`core-admin-${currentProject}`)) {
    return true;
  }

  return false;
};

const permission = { isProjectAdmin, isOnboardingAdmin };

export default permission;
