import api from '../store/api';

const queuedAPI = api.injectEndpoints({
  endpoints: build => ({
    get: build.query({
      query: id => `/queued?id=${id}`,
    }),
    delete: build.mutation({
      query: id => ({
        url: `/queued?id=${id}`,
        method: 'DELETE',
      }),
    }),
    getBulks: build.query({
      query: companyName => `queued/bulks?project=${encodeURIComponent(companyName)}`,
    }),
  }),
});

export const { useGetQuery, useDeleteMutation, useGetBulksQuery } = queuedAPI;
export default queuedAPI;
