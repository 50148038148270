import { setToken, resetToken } from './authSlice';
import localStorage from '../utils/localStorage';

const authMiddleware = () => next => action => {
  if (setToken.match(action)) {
    const { payload } = action;
    localStorage.setAuthToken(payload);
  } else if (resetToken.match(action)) {
    localStorage.setAuthToken(null);
  }
  return next(action);
};

export default authMiddleware;
