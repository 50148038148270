import React, { useEffect, useRef, useContext, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectToken } from '../auth/authSlice';
import { useQueuedContext } from '../queued/context';

const WEBSOCKET_URL = process.env.REACT_APP_WS;

const WebsocketContext = React.createContext();

const INITIAL = 'idle';
const initialProject = {
  id: null,
  status: INITIAL,
  usersStatus: INITIAL,
  certificationStatus: INITIAL,
};

const initialCertification = {
  element: null,
  status: INITIAL,
};

const WebsocketProvider = function ({ children }) {
  const token = useSelector(selectToken);

  const ws = useRef(null);
  const [connected, setConnected] = useState(false);
  // subscriptions

  const [project, setProject] = useState(initialProject);
  const [certification, setCertification] = useState(initialCertification);
  const [projectsStatus, setProjectsStatus] = useState(INITIAL);
  const [meStatus, setMeStatus] = useState(INITIAL);

  const subscription = {
    project,
    projectsStatus,
    certification,
    meStatus,
  };

  const { addQueued } = useQueuedContext();

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (token) {
      try {
        ws.current = new WebSocket(`${WEBSOCKET_URL}?token=${encodeURIComponent(token)}`);
        ws.current.onopen = () => {
          setConnected(true);
        };
        ws.current.onerror = () => {
          setConnected(false);
        };
      } catch {
        setConnected(false);
      }
    } else {
      ws.current?.close();
      setConnected(false);
    }

    return () => {
      ws.current?.close();
      return undefined;
    };
  }, [token]);

  useEffect(() => {
    if (!ws.current) {
      return false;
    }
    ws.current.onmessage = message => {
      const { data } = message;
      const response = JSON.parse(data);
      const { event, element } = response;

      if (event === 'pong') {
        return;
      }
      switch (element.type) {
        case 'onboarding_project':
          setProject({ ...project, status: event });
          break;
        case 'onboarding_users':
          setProject({ ...project, usersStatus: event });
          break;
        case 'onboarding_projects':
          setProjectsStatus(event);
          break;
        case 'onboarding_certification':
          setProject({ ...project, certificationStatus: event });
          break;
        case 'certification':
          setCertification({ ...certification, status: event });
          break;
        case 'me':
          setMeStatus(event);
          break;
        case 'queued':
          addQueued({ event, ...element.id });
          break;
        default:
          break;
      }
    };
    return undefined;
  }, [project, certification]);

  useEffect(() => {
    if (ws.current && connected) {
      const interval = setInterval(async () => {
        if (ws.current && ws.current.readyState === 1) {
          ws.current.send(JSON.stringify({ action: 'ping' }));
        }
      }, 1000 * 60 * 5);

      setTimeout(() => clearInterval(interval), 1000 * 60 * 60 * 24);

      return () => {
        clearTimeout(interval);
        setConnected(false);
        return undefined;
      };
    }
    return undefined;
  }, [connected, ws.current]);

  const subscribe = useCallback(
    message => {
      setMessages([...messages, message]);
    },
    [messages, setMessages],
  );

  const changeStatus = useCallback(
    (value, element) => {
      switch (element) {
        case 'onboarding_project':
          setProject({ ...project, status: value });
          break;
        case 'onboarding_users':
          setProject({ ...project, usersStatus: value });
          break;
        case 'onboarding_projects':
          setProjectsStatus(value);
          break;
        case 'onboarding_certification':
          setProject({ ...project, certificationStatus: value });
          break;
        case 'certification':
          setCertification({ element, status: value });
          break;
        case 'me':
          setMeStatus(value);
          break;
        default:
          break;
      }
    },
    [project],
  );

  useEffect(() => {
    let mounted = true;
    if (connected && messages.length > 0) {
      const processed = [];
      messages.forEach(message => {
        switch (message.type) {
          case 'onboarding_project': {
            ws.current.send(
              JSON.stringify({
                action: 'subscribe',
                element: { type: message.type, item: message.item },
              }),
            );
            setProject({ ...project, id: message.item, status: 'subscribing' });

            break;
          }
          case 'onboarding_users': {
            ws.current.send(
              JSON.stringify({
                action: 'subscribe',
                element: { type: message.type },
              }),
            );
            setProject({ ...project, usersStatus: 'subscribing' });
            break;
          }
          case 'onboarding_certification': {
            ws.current.send(
              JSON.stringify({
                action: 'subscribe',
                element: { type: message.type },
              }),
            );
            setProject({ ...project, certificationStatus: 'subscribing' });
            break;
          }
          case 'certification': {
            ws.current.send(
              JSON.stringify({
                action: 'subscribe',
                element: { type: message.type, item: message.item },
              }),
            );
            setCertification({ element: message.item, status: 'subscribing' });
            break;
          }
          default:
            break;
        }
        processed.push(message);
      });
      if (mounted) {
        setMessages(messages.filter(element => !processed.includes(element)));
      }
    }

    return () => {
      mounted = false;
      return undefined;
    };
  }, [messages, connected]);

  const memoValue = useMemo(
    () => ({ subscription, subscribe, changeStatus }),
    [subscription, subscribe, changeStatus],
  );

  return <WebsocketContext.Provider value={memoValue}>{children}</WebsocketContext.Provider>;
};

WebsocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useWebsocketContext = () => {
  const ctx = useContext(WebsocketContext);
  if (ctx === undefined) {
    throw new Error('useConnectionState must be used within a DataProvider');
  }

  return ctx;
};

export { WebsocketProvider, useWebsocketContext };
