import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import bn from '../../../utils/bemnames';

const Content = function ({ tag: Tag, className, ...restProps }) {
  const bem = bn.create('content');
  const classes = bem.b(className);

  return <Tag className={classes} {...restProps} />;
};

Content.propTypes = {
  tag: PropTypes.func,
  className: PropTypes.string,
};

Content.defaultProps = {
  tag: Container,
  className: 'journey-content',
};

export default Content;
