import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import RSelect from 'react-select';
import permission from '../../utils/permission';
import { selectMeSettings } from '../../me/meSlice';
import { useSetSettingMutation, useMeQuery } from '../../me/meApi';
import { useWebsocketContext } from '../../websocket/context';

const { isOnboardingAdmin } = permission;
const CREATE_DASHBOARD = 'create-dashboard';
const EMPTY_DASHBOARD = 'empty-dashboard';

const mapValue = (userProjects, currentProject, roles) => {
  const initialArr = [];
  const processOptions = projects => {
    const mappedOptions = [];
    projects
      .filter(pr => !pr.project_archived && !pr.projectId !== currentProject)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(opt => {
        mappedOptions.push({
          value: opt.projectId,
          label: opt.name,
          name: opt.name,
          projectId: opt.projectId,
        });
      });
    return mappedOptions;
  };
  if (isOnboardingAdmin(roles)) {
    if (!userProjects.length) {
      initialArr.push({ value: EMPTY_DASHBOARD, label: '-' });
      initialArr.push({ value: CREATE_DASHBOARD, label: 'Create New Dashboard' });
      return [...initialArr, ...processOptions(userProjects)];
    }

    if (!userProjects.find(project => project.projectId === currentProject)) {
      initialArr.push({ value: EMPTY_DASHBOARD, label: ' - ' });
      initialArr.push({ value: CREATE_DASHBOARD, label: 'Create New Dashboard' });
      return [...initialArr, ...processOptions(userProjects)];
    }

    initialArr.push({ value: CREATE_DASHBOARD, label: 'Create New Dashboard' });
  }

  return [...initialArr, ...processOptions(userProjects)];
};

const ProjectSelection = function ({ openBlankForm }) {
  const [disable, setDisable] = useState(false);
  const { currentProject, projects, roles } = useSelector(selectMeSettings);
  const [userProjects, setUserProjects] = useState(mapValue([...projects], currentProject, roles));
  const [active, setActiveProject] = useState(
    userProjects.find(project => project.projectId === currentProject),
  );
  const [setSetting] = useSetSettingMutation();
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    subscription: { projectsStatus },
    changeStatus,
  } = useWebsocketContext();
  const { refetch } = useMeQuery();

  useEffect(() => {
    setUserProjects(mapValue([...projects], currentProject, roles));
  }, [JSON.stringify(projects)]);

  useEffect(() => {
    const path = pathname.split('/');
    if (path.length > 3 && path[1] === 'journey') {
      path[2] = currentProject;
      history.push(path.join('/'));
    }
  }, [currentProject, pathname]);

  useEffect(() => {
    if (projectsStatus === 'INSERT' || projectsStatus === 'MODIFY') {
      refetch();
      changeStatus('processed', 'onboarding_projects');
    }
  }, [projectsStatus]);

  const onChangeProject = selection => {
    if (selection && selection.value) {
      const { value } = selection;
      if (value === CREATE_DASHBOARD) {
        openBlankForm();
        return false;
      }
      setSetting({ currentProject: +value });
      setActiveProject(selection);
      setDisable(true);
    }
    return false;
  };

  return (
    <div style={{ paddingLeft: 10, width: '100%' }}>
      <RSelect
        type="select"
        name="selectCustomer"
        value={active}
        onChange={onChangeProject}
        style={{ marginTop: '8px', cursor: 'pointer' }}
        isDisabled={disable}
        options={userProjects}
      />
    </div>
  );
};

ProjectSelection.propTypes = {
  openBlankForm: PropTypes.func.isRequired,
};

export default ProjectSelection;
