import { BACKEND_URL } from '../../utils/utils';

export const DATA_TYPE = {
    customer: 'customer',
    service: 'service',
    item: 'item',
    asset: 'asset',
    pdf: 'pdf',
    vendor: 'vendor',
    warehouse: 'warehouse',
    serviceForm: 'serviceForm',
    other: 'other'
}

export const CSV = { ext: '.csv', contentType: 'text/csv' };
export const XLS = { ext: '.xls', contentType: 'application/vnd.ms-excel' };
export const XLSX = { ext: '.xlsx', contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
export const MDB = { ext: '.mdb', contentType: 'application/octet-stream' };
export const BAK = { ext: '.bak', contentType: 'application/octet-stream' };
export const ACCDB = { ext: '.accdb', contentType: 'application/msaccess' };
export const PDF = { ext: '.pdf', contentType: 'application/pdf' };
export const DOC = { ext: '.doc', contentType: 'application/msword' };
export const DOCX = { ext: '.docx', contentType: 'application/vnd.openxmlformats-officedocument.wordprocessing' };
export const IMAGE_TYPE = { ext: 'image/*', contentType: 'application/octet-stream' };
export const AUDIO_TYPE = { ext: 'audio/*', contentType: 'application/octet-stream' };
export const VIDEO_TYPE = { ext: 'video/*', contentType: 'application/octet-stream' };

export const DATA_INTAKE = {
    Customers: {
        title: 'Customers',
        dataType: DATA_TYPE.customer,
        description: 'In ServiceTrade, customers are a combination of a location (ship-to / job-site) and a company (bill-to).  One company can have many locations.',
        requirements: 'This file should contain the name and address of the customer bill-to (company), the name and address of the customer ship-to (location), phone number info for both the location or company, primary contact info, customer notes and anything else you feel is necessary to be tied to the customer.',
        supportedTypes: [CSV, XLS, XLSX, MDB, BAK, ACCDB],
    },
    Services: {
        title: 'Services',
        dataType: DATA_TYPE.service,
        description: 'In ServiceTrade, services are the description and details of the work you do for your customers.  Services can be recurring or planned one-time services.',
        requirements: 'This list should contain at least: next due date, frequency of service (ie annual, quarterly...), description of service, etc. Each Service must also match to a location from your Customers data file.',
        supportedTypes: [CSV, XLS, XLSX, MDB, BAK, ACCDB],
    },
    Items: {
        title: 'Items',
        dataType: DATA_TYPE.item,
        description: ' In ServiceTrade, Items are a list of the parts, materials, labor line items, etc you use to track what gets used on a job and/or what you list on your invoices when billing the customer.',
        requirements: 'This list should include the description of the part or item, type (part, material, service, etc), cost to you (if applicable), price to the customer (if available) and any item code or SKU number associated with the item.',
        supportedTypes: [CSV, XLS, XLSX, MDB, BAK, ACCDB],
    },
    Assets: {
        title: 'Assets',
        dataType: DATA_TYPE.asset,
        description: "In ServiceTrade, Assets are equipment or systems that you service or inspect for your customers.  Assets reside on your customers' locations and can be managed by both the office and the tech in the field.",
        requirements: 'This list should contain details like the make, model, serial number, etc for each piece of equipment. Each Asset must also match to a location from your Customers data file.',
        supportedTypes: [CSV, XLS, XLSX, MDB, BAK, ACCDB],
    },
    PDFForms: {
        title: 'PDF Forms',
        dataType: DATA_TYPE.pdf,
        description: 'Fillable paperwork in ServiceTrade is based on various paper documents your tech fills out at a customer location during the job.  These include inspection forms, maintenance checklists or other types of documentation. ',
        requirements: 'This needs to be in Excel, Word or preferably PDF format and be files that you print or use for inspections and work order checklists while on the job.',
        supportedTypes: [PDF, DOC, DOCX, XLS, XLSX],
    },
    Vendors: {
        title: 'Vendors',
        dataType: DATA_TYPE.vendor,
        description: '',
        requirements: '',
        supportedTypes: [XLS, XLSX, CSV],
    },
    Warehouses: {
        title: 'Warehouses',
        dataType: DATA_TYPE.warehouse,
        description: 'Any entity that is providing a service, part or labor that will be used on a job.',
        requirements: '',
        supportedTypes: [XLS, XLSX, CSV],
    },
    ServiceFormsFiles: {
        title: 'ServiceForms Files',
        dataType: DATA_TYPE.serviceForm,
        description: '',
        requirements: '',
        supportedTypes: [PDF, DOC, DOCX, XLS, XLSX, CSV],
    },
    OTHER: {
        title: 'Other',
        dataType: DATA_TYPE.other,
        description: 'This section is meant to allow you to upload any additional data that does not make sense to upload elsewhere. Any data uploaded here may need to be scoped by your Project Manager before we begin work.',
        requirements: 'This needs to be in Excel, Word or preferably PDF format and be files that you print or use for inspections and work order checklists while on the job.',
        supportedTypes: [IMAGE_TYPE, AUDIO_TYPE, VIDEO_TYPE, PDF, DOC, DOCX, XLS, XLSX, CSV, MDB, BAK, ACCDB],
    },
}

export const URL_BUCKET_EXIST = `${BACKEND_URL}/storage/bucket/has`;
export const URL_FILE_SIGNED_URL = `${BACKEND_URL}/storage/signedurl/create`;
export const URL_GET_FILES = `${BACKEND_URL}/storage/bucket/getitems`;
export const URL_DELETE_FILE = `${BACKEND_URL}/storage/bucket/delete`;

export const URL_GET_ASANA_TASK = `${BACKEND_URL}/asana/task/get`;

export const URL_GET_TASKBYTYPE = `${BACKEND_URL}/project/task/type/get`;
export const URL_SUBMIT_TASK = `${BACKEND_URL}/asana/task`;

export const bucketUrl = () => {
    return `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_REGION}.amazonaws.com`;
}

export const DataIntakeStatus = {
    CREATED: 'created',
    SUBMITTED: 'submitted',
    APPROVED: 'approved'
}

export const getTag = (type) => {
    return `${type}=${type}`
}

export const getTags = (arr) => {
    return arr.map(type => `${type}=${type}`).join('&')
}

const dataTypeNames = [ 
    { type: 'customer', name: 'customer_data' }, 
    { type: 'service', name: 'services_data'},
    { type: 'item', name: 'item_data' }, 
    { type: 'asset', name: 'asset_data' },
    { type: 'pdf', name: 'paperwork_data' }, 
    { type: 'vendor', name: 'vendor_data' }, 
    { type: 'warehouse', name: 'warehouse_data' }, 
    { type: 'serviceForm', name: 'serviceforms_data' }, 
    { type: 'other', name: 'other_data'}
];

export const typedTasks = taskList => {
    return taskList.map(element => {
        const typeArray = element.custom_fields.filter(item => item.name === 'Type' && item.display_value );
        const progressArray = element.custom_fields.filter(item => item.name === 'Progress' && item.display_value );
        const typeName = typeArray.length > 0 ? typeArray[0].display_value : '';
        const progressName = progressArray.length > 0 ? progressArray[0].display_value : '';
        const dataName = dataTypeNames.find(el => el.name === typeName);
        if(!dataName) {
            return { ...element, progress: progressName};
        }

        return ({ ...element, dataType: dataName.type, taskId: element.gid, progress: progressName });

    });
}

export const TAGS = dataTypeNames.map(item => item.type).join(",");

export const initialTaggedFiles = () => {
    const tags = TAGS.split(",");
    const result = new Map();
    tags.forEach(tag => {
        result.set(tag, []);
    });

    return result;
}