const AUTH = 'auth';
const PAGE_REFRESHED = 'page-has-been-force-refreshed';

const setLocalStorageItem = (id, value) => localStorage.setItem(id, JSON.stringify(value));

const getLocalStorageItem = id => localStorage.getItem(id);

const removeLocalStorageItem = id => localStorage.removeItem(id);

export const setAuthDetails = value => setLocalStorageItem(AUTH, value);

export const setAuthToken = value => setAuthDetails({ token: value });

export const getAuthDetails = () => {
  const auth = getLocalStorageItem(AUTH);

  return auth ? JSON.parse(auth) : '';
};

export const getAuthToken = () => {
  const auth = getAuthDetails();

  return auth ? auth?.token : '';
};

export const removeAuth = () => removeLocalStorageItem(AUTH);

export const getPageRefreshed = () => {
  const page = getLocalStorageItem(PAGE_REFRESHED);
  return page ? JSON.parse(page) : false;
};

export const setPageRefreshed = value => setLocalStorageItem(PAGE_REFRESHED, value);

export default { getAuthToken, setAuthToken, removeAuth, getPageRefreshed, setPageRefreshed };
