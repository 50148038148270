import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdClearAll, MdExitToApp, MdHelp } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
  Button,
  Nav,
  Navbar,
  NavLink,
  NavItem,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { useHistory } from 'react-router';
import bn from '../../../utils/bemnames';
import Avatar from '../../../../components/Avatar';
import { UserCard } from '../../../../components/Card';
import { selectMeDetails, selectMeSettings } from '../../../me/meSlice';
import { useSetSettingMutation, useSignOutMutation } from '../../../me/meApi';
import { selectToken } from '../../../auth/authSlice';
import { toggleSidebar } from '../../../utils/sidebar';
import CurrentProject from '../CurrentProject';
import ModalVideoJourney from '../../../../components/ModalVideoJourney';
import { detailsByPath } from '../../../../utils/utils';
import AuthProviders from '../AuthProviders';
import Auth0LogOut from '../Auth0LogOut';

const bem = bn.create('header');

const Header = function () {
  const token = useSelector(selectToken);
  const refUserCard = useRef(null);
  const history = useHistory();
  const { avatar, company, firstName, lastName, type } = useSelector(selectMeDetails);
  const { tutorials, roles, currentProject } = useSelector(selectMeSettings);
  const { pathname } = useLocation();
  const [setSetting] = useSetSettingMutation();
  const [signOut] = useSignOutMutation();
  const path = pathname.split('/');
  const currentPath = path[path.length - 1];
  const [openUserCard, setOpenUserCard] = useState(false);
  const [videoIsOpen, setVideoIsOpen] = useState(false);
  const toggleUserCard = () => {
    setOpenUserCard(!openUserCard);
  };
  const handleToggleSidebar = event => {
    event.preventDefault();
    event.stopPropagation();
    toggleSidebar();
  };

  const toggleVideo = () => {
    setVideoIsOpen(!videoIsOpen);
  };

  const logout = () => {
    signOut();
    history.push('/login');
  };

  useEffect(() => {
    let mounted = true;
    if (tutorials && Array.isArray(tutorials) && !tutorials.find(item => item === currentPath)) {
      if (detailsByPath(currentPath).video !== '' && mounted) {
        setVideoIsOpen(true);
      }
    }

    return () => {
      mounted = false;
      return undefined;
    };
  }, [currentPath, tutorials]);

  const handleSubmitTutorial = useCallback(() => {
    setSetting({ tutorials: [...tutorials, currentPath] }).then(() => {
      setVideoIsOpen(false);
    });
  }, [token, setVideoIsOpen, tutorials, setSetting, currentPath]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (refUserCard.current && !refUserCard.current.contains(event.target)) {
        toggleUserCard();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [toggleUserCard]);

  return (
    <>
      <header>
        <Navbar light expand className={bem.b('bg-white')}>
          <Nav navbar className="mr-2">
            <Button outline onClick={handleToggleSidebar}>
              <MdClearAll size={25} />
            </Button>
          </Nav>
          <Nav navbar style={{ width: '80%', maxWidth: '1000px' }}>
            <CurrentProject currentProject={currentProject} roles={roles} />
          </Nav>
          <Nav navbar className={bem.e('nav-right')}>
            <NavItem style={{ margin: 'auto', cursor: 'pointer' }}>
              <OndemandVideoIcon onClick={() => setVideoIsOpen(true)} />
            </NavItem>
            <NavItem>
              <NavLink id="Popover2">
                <Avatar src={avatar && avatar.small} onClick={() => {}} className="can-click" />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={openUserCard}
                toggle={toggleUserCard}
                target="Popover2"
                className="p-0 border-0"
                style={{ minWidth: 250, boxShadow: '5px 5px 5px #2d3338d6' }}
              >
                {/* <OutsideClickHandler onOutsideClick={toggleUserCard}> */}
                <div ref={refUserCard}>
                  <PopoverBody className="p-0">
                    <UserCard
                      avatar={avatar && avatar.large}
                      title={`${firstName} ${lastName}`}
                      subtitle={company}
                      className=""
                    >
                      <ListGroup flush>
                        <a
                          href="https://support.servicetrade.com"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                          rel="noopener noreferrer"
                        >
                          <ListGroupItem
                            tag="button"
                            action
                            className="border-light"
                            style={{ borderRadius: 0 }}
                          >
                            <MdHelp /> Help
                          </ListGroupItem>
                        </a>
                        {type === 'journey' ? (
                          <ListGroupItem
                            tag="button"
                            action
                            className="border-light"
                            onClick={logout}
                          >
                            <MdExitToApp /> Signout
                          </ListGroupItem>
                        ) : (
                          <AuthProviders>
                            <Auth0LogOut />
                          </AuthProviders>
                        )}
                      </ListGroup>
                    </UserCard>
                  </PopoverBody>
                  {/* </OutsideClickHandler> */}
                </div>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
      </header>
      {currentPath && (
        <ModalVideoJourney
          videoIsOpen={videoIsOpen}
          toggle={toggleVideo}
          path={currentPath}
          handleSubmitTutorial={handleSubmitTutorial}
        />
      )}
    </>
  );
};

export default Header;
