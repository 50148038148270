import { isRejectedWithValue } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import { displayError, ERRORS } from '../utils/messages';

const errorMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.status) {
        case 401: {
          displayError(`${ERRORS.AUTH}`);
          dispatch(resetToken());
          break;
        }
        case 404: {
          displayError(`${ERRORS.NOT_FOUND}`);
          break;
        }
        case 500: {
          displayError(`${ERRORS.SERVER}`);
          break;
        }
        default:
          break;
      }
    }

    return next(action);
  };

export default errorMiddleware;
