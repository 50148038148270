const breakpoint = width => {
  if (width < 575) {
    return 'xs';
  }

  if (width > 576 && width < 767) {
    return 'sm';
  }

  if (width > 768 && width < 991) {
    return 'md';
  }

  if (width > 992 && width < 1199) {
    return 'lg';
  }

  if (width > 1200) {
    return 'xl';
  }

  return 'xs';
};

export default breakpoint;
