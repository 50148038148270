import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import queuedAPI from './queuedAPI';

const queuedSlice = createSlice({
  name: 'queued',
  initialState: [],
  reducers: {
    remove: (state, { payload }) => {
      const { id } = payload;
      return state.filter(item => item.guid !== id);
    },
  },
  extraReducers: build => {
    build.addCase(resetToken, () => []);
    build.addMatcher(queuedAPI.endpoints.get.matchFulfilled, (state, { payload }) => {
      const { data } = payload;
      return data ? [...state, data] : state;
    });
  },
});

export const { remove } = queuedSlice.actions;

export const selectQueued = state => state.queued;

export default queuedSlice.reducer;
