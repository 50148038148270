import { createSlice } from '@reduxjs/toolkit';
import { resetToken } from '../auth/authSlice';
import { resetProject } from '../project/projectSlice';
import usersApi from './usersApi';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  extraReducers: build => {
    build.addCase(resetToken, () => []);
    build.addCase(resetProject, () => []);
    build.addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, { payload }) => {
      const { data } = payload;
      if (data && data.users) {
        return [...data.users];
      }
      return state;
    });
  },
});

export const usersActions = usersSlice.actions;
export const selectUsers = state => state.users;

export default usersSlice.reducer;
