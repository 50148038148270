const BACKEND_URL = `${process.env.REACT_APP_API}${process.env.REACT_APP_STAGE}`;

export const CERTIFICATES = {
  GET: `${BACKEND_URL}/certificates`,
  GET_USERS: `${BACKEND_URL}/certificates/users`,
  USER: `${BACKEND_URL}/certificates/user`,
  ENROLL: `${BACKEND_URL}/certificates/user/enroll`,
  UNENROLL: `${BACKEND_URL}/certificates/user/unenroll`,
};

export const DATA_INTAKE = {
  BUCKET: `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_REGION}.amazonaws.com`,
  BUCKET_EXIST: `${BACKEND_URL}/storage/bucket/has`,
  SIGNED_URL: `${BACKEND_URL}/storage/signedurl/create`,
  GET_FILES: `${BACKEND_URL}/storage/bucket/getitems`,
  GET_FILE: `${BACKEND_URL}/storage/bucket/getFile`,
  DELETE_FILE: `${BACKEND_URL}/storage/bucket/delete`,
  SUBMIT_TASK: `${BACKEND_URL}/asana/task`,
};

export const USERS = {
  IS_LOCAL: `${BACKEND_URL}/user/isLocal`,
  AUTH: `${BACKEND_URL}/user/auth`,
  ST_UPDATE: `${BACKEND_URL}/user/st`,
  VALIDATE: `${BACKEND_URL}/user/validate`,
  USER_CREATE: `${BACKEND_URL}/user`,
  PASSWORD_INVITE: `${BACKEND_URL}/user/password/invite`,
};

export default BACKEND_URL;
