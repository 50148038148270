const QUEUED_CALL = {
  CERTIFICATE: {
    USER_BY_EMAIL: 'CERTIFICATE_USER_BY_EMAIL',
    CREATE_USER: 'CERTIFICATE_CREATE_USER',
    USER_ENROLL: 'CERTIFICATE_USER_ENROLL',
    USER_UNENROLL: 'CERTIFICATE_USER_UNENROLL',
    BULK_EDITING: 'CERTIFICATE_BULK_EDITING',
  },
};

export default QUEUED_CALL;
