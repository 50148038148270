import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useMeQuery } from '../me/meApi';
import { selectMeDetails, selectMeSettings } from '../me/meSlice';
import { useWebsocketContext } from '../websocket/context';

const useMe = function (props) {
  const { token } = props;
  const meDetails = useSelector(selectMeDetails);
  const [me, setMe] = useState(meDetails);
  const meSettings = useSelector(selectMeSettings);
  const [settings, setSettings] = useState({ ...meSettings });
  const [pages, setPages] = useState([...meSettings.pages]);

  const [skip, setSkip] = useState(true);

  const { isLoading, refetch } = useMeQuery(null, { skip });

  const {
    subscription: { meStatus, accounts },
    changeStatus,
  } = useWebsocketContext();

  // refetch if project modified
  useEffect(() => {
    if (meStatus === 'MODIFY') {
      refetch();
      changeStatus('processed', 'me');
    }
  }, [meStatus]);

  // refetch if project list modified
  useEffect(() => {
    if (accounts === 'MODIFY' || accounts === 'INSERT' || accounts === 'REMOVE') {
      refetch();
      changeStatus('processed', 'me');
    }
  }, [accounts]);

  useEffect(() => {
    if (!isEqual(settings.pages, pages)) {
      setPages([...settings.pages]);
    }
  }, [settings.pages]);

  const home = useMemo(() => settings.home, [settings.home]);

  useEffect(() => {
    if (token) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [token]);

  useEffect(() => setMe(meDetails), [meDetails]);

  useEffect(() => setSettings({ ...meSettings }), [meSettings]);

  return { isLoading, me, settings, pages, home };
};

export default useMe;
