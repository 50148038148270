import React from 'react';
import { Col, Card } from 'reactstrap';
import CardTitle from 'reactstrap/lib/CardTitle';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import bn from '../../utils/bemnames';

const bem = bn.create('servicetrade-accounts');

const ServiceTradeAccounts = function (props) {
  const { title, list } = props;
  const cardClass = bem.b('card-row border-0 flex-row user-card-item');

  return (
    <Card className="border-0">
      {title !== '' && <CardTitle>{title}</CardTitle>}
      {list.map(item => (
        <Card key={uuidv4()} className={cardClass}>
          <Col md="10">{`${item.name}`}</Col>
        </Card>
      ))}
    </Card>
  );
};

ServiceTradeAccounts.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      accountName: PropTypes.string,
      accountType: PropTypes.string,
    }),
  ).isRequired,
};

export default ServiceTradeAccounts;
