/* eslint-disable react/prop-types */
import React from 'react';
// import { FormLabel } from '@mui/material';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Col, Input, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'utils/propTypes';
import OnOffSwitch from './Switch/OnOffSwitch';
import NotificationAccordion from './NotificationAccordion';

const notificationFrequencyChoiceOptions = ['', ' Daily', 'Weekly', 'BiWeekly', 'Monthly'];

const NotificationSettings = function (props) {
  const {
    summaryDigestNotification,
    setSummaryDigestNotification,
    overdueTaskNotification,
    setOverdueTaskNotification,
    overdueTask_notification_active,
    setOverdueNotification,
    summaryDigest_notification_active,
    setSummaryNotification,
  } = props;

  const {
    date: summaryDate,
    subscribers: summarySubscribers,
    frequency: summaryFrequency,
  } = summaryDigestNotification.body;

  const {
    date: overdueTaskDate,
    subscribers: overdueTaskSubscribers,
    frequency: overdueTaskFrequency,
  } = overdueTaskNotification.body;

  const { sponsorEmailSummary: overdueTaskEmailSummary } = overdueTaskNotification;
  const { sponsorEmailSummary } = summaryDigestNotification;

  const handleSummaryDigestChange = e => {
    const { name, value } = e.target;
    setSummaryDigestNotification({
      ...summaryDigestNotification,
      body: { ...summaryDigestNotification.body, [name]: value },
    });
  };

  const summaryDigestSubscriberChange = e => {
    setSummaryDigestNotification({
      ...summaryDigestNotification,
      sponsorEmailSummary: e.target.value,
    });
  };

  const handleOverdueTaskChange = e => {
    const { name, value } = e.target;
    setOverdueTaskNotification({
      ...overdueTaskNotification,
      body: { ...overdueTaskNotification.body, [name]: value },
    });
  };

  const overdueSubscriberChange = e => {
    setOverdueTaskNotification({
      ...overdueTaskNotification,
      sponsorEmailSummary: e.target.value,
    });
  };

  return (
    <div>
      <OnOffSwitch
        label="Enable Summary Notifications"
        status={summaryDigest_notification_active}
        setValue={setSummaryNotification}
        thumbColor="#cdbea1"
      />
      <OnOffSwitch
        label="Enable Overduetask notifications"
        status={overdueTask_notification_active}
        setValue={setOverdueNotification}
        thumbColor="#cdbea1"
      />

      {summaryDigest_notification_active && (
        <div>
          <FormGroup style={{ marginTop: 15 }} row>
            <Label style={{ marginLeft: 20 }} sm={5} lg={4}>
              Summary Digest{' '}
            </Label>

            <Row style={{ marginLeft: 10 }}>
              <Label style={{ marginTop: 5 }}>Send</Label>
              <Col>
                <Input
                  id="frequency"
                  name="frequency"
                  type="select"
                  onChange={handleSummaryDigestChange}
                  multiple={false}
                  value={summaryFrequency}
                >
                  {notificationFrequencyChoiceOptions.map(item => (
                    <option key={uuidv4()}>{item}</option>
                  ))}
                </Input>
              </Col>
              <Label style={{ marginTop: 5 }}>on</Label>
              <Col>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  onChange={handleSummaryDigestChange}
                  value={summaryDate}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Label sm={12} md={5}>
                Executive Sponsor Email:
              </Label>
              <Col sm={12} md={6}>
                <Input
                  type="email"
                  id="sponsorEmail"
                  value={sponsorEmailSummary}
                  onChange={summaryDigestSubscriberChange}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
            <Col sm={12} md={7}>
              <NotificationAccordion
                subscribers={summarySubscribers}
                setSubscribers={setSummaryDigestNotification}
                bodyObject={summaryDigestNotification}
                title="Summary digest subscribers"
              />
            </Col>
          </FormGroup>
        </div>
      )}

      {overdueTask_notification_active && (
        <div>
          <FormGroup row>
            <Label style={{ marginLeft: 20 }} sm={5} lg={4}>
              OverDue Tasks
            </Label>

            <Row style={{ marginLeft: 10 }}>
              <Label style={{ marginTop: 5 }}>Send</Label>
              <Col>
                <Input
                  id="frequency"
                  name="frequency"
                  type="select"
                  multiple={false}
                  value={overdueTaskFrequency}
                  onChange={handleOverdueTaskChange}
                >
                  {notificationFrequencyChoiceOptions.map(item => (
                    <option key={uuidv4()}>{item}</option>
                  ))}
                </Input>
              </Col>
              <Label style={{ marginTop: 5 }}>on</Label>
              <Col>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  onChange={handleOverdueTaskChange}
                  value={overdueTaskDate}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Label sm={12} md={5}>
                Executive Sponsor Email:
              </Label>

              <Col sm={12} md={6}>
                <Input
                  type="email"
                  id="sponsorEmail"
                  value={overdueTaskEmailSummary}
                  onChange={overdueSubscriberChange}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
            <Col sm={12} md={7}>
              <NotificationAccordion
                subscribers={overdueTaskSubscribers}
                setSubscribers={setOverdueTaskNotification}
                bodyObject={overdueTaskNotification}
                title="Overdue task subscribers"
              />
            </Col>
          </FormGroup>
        </div>
      )}
    </div>
  );
};

NotificationSettings.propTypes = {
  sponsorEmailSummary: PropTypes.string,
};

NotificationSettings.defaultProps = {
  sponsorEmailSummary: '',
};

export default NotificationSettings;
