import React, { useState, useEffect } from "react";
import PropTypes from 'utils/propTypes';
import ReactPlayer from 'react-player/lazy'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { detailsByPath } from '../utils/utils';

const ModalVideoJourney = (props) => {
    const { videoIsOpen, toggle, path, handleSubmitTutorial } = props;
    const [params, setParams] = useState(detailsByPath(path));

    const innerHtml = { __html: params.desc }

    const onEndedVideo = () => {
        console.log('Video ended')
    }

    useEffect(() => {
        setParams(detailsByPath(path));    
    }, [path]);
    
    return (
        <Modal
            size="lg"
            cssModule={{ 'modal-body': 'margin: 0 auto;' }}
            isOpen={videoIsOpen}
            backdrop={true}
            toggle={toggle}
            >
            <ModalHeader className={"text-center pb-0"} toggle={toggle} style={{ border: 'none' }}
                cssModule={{ 'modal-title': 'w-100 text-center' }}>
                {params.title}
            </ModalHeader>
            <ModalBody>
                <p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={innerHtml}></p>
                <div >
                    <ReactPlayer 
                        url={params.video} 
                        onEnded={onEndedVideo}
                        controls={true}
                        style={{ margin: '0 auto', }}
                        config={{ 
                            vimeo: {
                                playerOptions: {
                                    style: { 
                                        width: '100%' 
                                    }
                                }
                            }
                        }} />
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'center', border: 'none', padding: 0 }}>
                <Button color="primary" onClick={handleSubmitTutorial} style={{ minWidth: 200 }} >
                    Got it!
                </Button>
            </ModalFooter>
            <ModalFooter style={{ justifyContent: 'center', fontStyle: 'italic', border: 'none', padding: '10px 16px 20px 16px' }}>
                {params.descBottom}
            </ModalFooter>
        </Modal>
    );
}

ModalVideoJourney.propTypes = {
    videoIsOpen: PropTypes.bool,
    tooggle: PropTypes.func,
    path: PropTypes.string.isRequired,
    handleSubmitTutorial: PropTypes.func.isRequired,
};

export default ModalVideoJourney;

