export const PROJECT_STATE = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  ARCHIVED: 'archived',
};

export const getProjectState = (archived, disabled) => {
  if (archived) {
    return PROJECT_STATE.ARCHIVED;
  }
  if (disabled) {
    return PROJECT_STATE.DISABLED;
  }

  return PROJECT_STATE.ACTIVE;
};
